import React from 'react';

import SEO from 'src/components/seo';
import AcceptInvite from 'src/features/accept-invitation';
import queryString from 'query-string';

export default function Onboarding(props) {
  const pageProps = props;
  // const token = pageProps?.params && pageProps?.params.token ? pageProps.params.token : false;
  const token = queryString.parse(location.search) && queryString.parse(location.search).token ? queryString.parse(location.search).token : false;

  return (
    <>
      <SEO title="Accept invitation" />

      <AcceptInvite token={token} />
    </>
  );
}
