import React from 'react';

import { Hidden } from '@material-ui/core';

import DesktopAcceptInvite from './desktopAcceptInvite';
import MobileAcceptInvite from './mobileAcceptInvite';

export default function ({ token }) {
  return (
    <>
      <Hidden only="xs">
        <DesktopAcceptInvite token={ token } />
      </Hidden>

      <Hidden smUp>
        <MobileAcceptInvite token={ token } />
      </Hidden>
    </>
  );
}
