/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  makeStyles, IconButton, Typography, FormControl, InputLabel,
  OutlinedInput, FormHelperText, Button, Hidden,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import api from 'src/services/axios';

import Alert from 'src/components/alert';
import PhoneInput from 'src/components/phoneInput';

import { acceptInvite } from 'src/api/login';
import { setCurrentUser } from 'src/api/_common';

const useStyles = makeStyles(theme => ({
  buttonWrapper1: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBottom: theme.spacing(5),
  },
  buttonWrapper2: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(5),
    '& > *': {
      height: 53,
      [theme.breakpoints.down('sm')]: {
        width: 250,
      },
    },
  },
  icon: {
    margin: theme.spacing(0, 1),
  },
}));

const formSchema = yup.object().shape({
  token: yup
    .string()
    .required('Token is required'),
  name: yup
    .string()
    .max(64, 'Max 64 symbols')
    .required('Name is required'),
  phone: yup.string(),
  password: yup
    .string()
    .min(8, 'Password must be contain at least 8 characters')
    .matches(/[0-9]/, 'Password requires at least one digit')
    .matches(/[a-z]/, 'Password requires at least one lowercase letter')
    .matches(/[A-Z]/, 'Password requires at least one uppercase letter')
    .matches(/[^\w]/, 'Password requires at least one special symbol')
    .required('Password is required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Password and confirm password should match')
    .required('Confirm password is required'),
});

export default function AcceptInviteForm({ token }) {
  const classes = useStyles();

  const [showPassword, setShowPassword] = useState(false);

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [error, setError] = useState(false);

  const [countries, setCountries] = useState([]);

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const formik = useFormik({
    initialValues: {
      token,
      name: '',
      phone: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: formSchema,
    onSubmit: async (values, actions) => {
      actions.setSubmitting(true);

      const { status, message } = await acceptInvite(values);

      if (status === 'success') {
        setIsLoggedIn(true);
        // navigate('/me/overview', { replace: true });
      } else {
        setError(message);
      }
    },
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleGetCountriesList = (data) => {
    if (data.length < 0) {
      return true;
    }

    setCountries(data);

    return true;
  };

  const handleClick = () => {
    setError(false);
  };

  const handleSubmitSearch = (event) => {
    event.preventDefault();

    if (formik.isValid) {
      formik.submitForm();
    }
  };

  useEffect(async () => {
    if (!isLoggedIn) {
      return;
    }
    const { data: me } = await api.get('/me');
    setCurrentUser(me.data.user);

    if (!window.dataLayer) {
      window.dataLayer = [];
    }


    window.dataLayer.push(
      {
        "identity": {
          "account": {
            "id": me.data.user.id,
          },
          "user": {
            "id": me.data.user.id,
          },
        },
        "context": {
          "staging": process.env.DEVELOPMENT_LOGOUT,
          "impersonating": "false",
          "role": me.data.user.user_role,// "user"
        },
      }
    );

    window.dataLayer.push({
      "event": "login",
      "context": {
        "staging": process.env.DEVELOPMENT_LOGOUT,
      },
      "accountProperties": {
        "created_at": Date.now(),
      }
    });

    navigate('/me/overview/', { replace: true });
  }, [isLoggedIn]);

  return (
    <>
      <Typography variant="h1" align="center">
        Accept invite
      </Typography>

      <form
        className={classes.form}
        onClick={handleClick}
      >
        <input
          id="token"
          name="token"
          type="hidden"
          value={formik.values.token}
        />

        <FormControl variant="outlined" fullWidth margin="normal">
          <InputLabel name="name">Enter your name</InputLabel>

          <OutlinedInput
            id="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            label="Enter your name"
            name="name"
            value={formik.values.name}
            error={formik.errors.name && formik.touched.name}
          />

          {formik.errors.name && formik.touched.name ? (
            <FormHelperText component="div">
              <Alert severity="error">{formik.errors.name}</Alert>
            </FormHelperText>
          ) : null}
        </FormControl>

        {/* <FormControl variant="outlined" fullWidth margin="normal">
          <PhoneInput
            country
            name="phone"
            value={formik.values.phone}
            onChange={formik.setFieldValue}
            onBlur={formik.handleBlur}
            error={formik.errors.phone && formik.touched.phone}
          />
          <PhoneInput
            user="true"
            country
            name="phone"
            size="small"
            value={formik.values.phone}
            countriesList={handleGetCountriesList}
            onChange={formik.setFieldValue}
            error={formik.errors.phone && formik.touched.phone}
          />

          {formik.errors.phone && formik.touched.phone ? (
            <FormHelperText component="div" className={classes.textHelper}>
              <Alert severity="error">{formik.errors.phone}</Alert>
            </FormHelperText>
          ) : null}
        </FormControl> */}

        <FormControl variant="outlined" fullWidth margin="normal">
          <InputLabel htmlFor="password">Password</InputLabel>
          <OutlinedInput
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            id="password"
            label="Password*"
            name="password"
            type={showPassword ? 'text' : 'password'}
            value={formik.values.password}
            endAdornment={
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {
                  showPassword ? (
                    <VisibilityOff color="primary" className={classes.icon} />
                  ) : (
                    <Visibility color="primary" className={classes.icon} />
                  )
                }
              </IconButton>
            }
          />
          <FormHelperText component="div">
            {
              formik.errors.password && formik.touched.password ? (
                <Alert variant="filled" severity="error">
                  {formik.errors.password}
                </Alert>
              ) : null
            }
          </FormHelperText>
        </FormControl>

        <FormControl variant="outlined" fullWidth margin="normal">
          <InputLabel htmlFor="confirmPassword">Confirm password</InputLabel>
          <OutlinedInput
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            id="confirmPassword"
            label="Confirm password*"
            name="confirmPassword"
            type={showConfirmPassword ? 'text' : 'password'}
            value={formik.values.confirmPassword}
            endAdornment={
              <IconButton
                aria-label="toggle confirm password visibility"
                onClick={handleClickShowConfirmPassword}
                edge="end"
              >
                {
                  showConfirmPassword ? (
                    <VisibilityOff color="primary" className={classes.icon} />
                  ) : (
                    <Visibility color="primary" className={classes.icon} />
                  )
                }
              </IconButton>
            }
          />
          <FormHelperText component="div">
            {
              formik.errors.confirmPassword && formik.touched.confirmPassword ? (
                <Alert variant="filled" severity="error">
                  {formik.errors.confirmPassword}
                </Alert>
              ) : null
            }
          </FormHelperText>
        </FormControl>

        <FormHelperText component="div">
          {
            error ?
              (<Alert severity="error">{error}</Alert>) :
              null
          }
        </FormHelperText>

        <div className={classes.buttonWrapper2}>
          <Button
            color="secondary"
            variant="contained"
            type="submit"
            disableElevation
            disabled={formik.isSubmitting}
            onClick={(event) => handleSubmitSearch(event)}
            endIcon={
              <Hidden smDown>
                <ArrowForwardIcon className={classes.icon} />
              </Hidden>
            }
          >
            Accept invite
          </Button>
        </div>
      </form>
    </>
  );
}
